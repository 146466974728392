import React from 'react';
import { Link } from "gatsby"
import CollapseNav from './CollapseNav';
import { useScroll } from "../../hooks/useScroll";
import './style.css';
import { windowGlobal } from '../../utils/helperFunction';

export const navList = [
  {
    label: 'الرئيسية',
    link: '/',
  },
  {
    label: 'من نحن',
    link: '/#aboutArea',
  },
  {
    label: 'أقسام النظام',
    link: '/#servicesArea',
  },
  {
    label: 'المميزات',
    link: '/#visionArea',
  },
  {
    label: 'التواصل',
    link: '/contact-us',
  },
];

const Navbar = () => {
  const { scrollY } = useScroll();
  return (
    <nav className="navbar navbar-expand-lg navbar-colored">
      <div className="container">
        <Link className="navbar-brand" to="/"> 
          <img className="logo" src="/images/logo-galm.png" style={{ height: scrollY === 0 ? 65 : 40 }} alt="logo" />
        </Link>
        {windowGlobal && windowGlobal.innerWidth < 720 ? 
          <CollapseNav list={navList} /> : (
          <div className="flex" id="main-navbar">
            {navList.map((nav, i) => (
              <div className="nav-item" key={i}>
                <Link to={nav.link} className="nav-link">{nav.label}</Link>
              </div>
            ))}
            <div className="nav-item log-in">
              <Link className="nav-link flex-center bg-blue radius-5px transition-3" to="/login">تسجيل الدخول</Link>
            </div>
          </div>)
        }
      </div>
    </nav>
  )
}

export default Navbar;
