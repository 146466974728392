import React, { useState } from 'react'
import { Link } from "gatsby"

const CollapseNav = ({
  list
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setOpen(!open)}
        className="navbar-toggler" type="button"
      >
        <span className="fa fa-bars" />
      </button>
      {open &&
        <div className="small-list">
          {list.map(nav => <Link to="/">{nav}</Link>)}
        </div>}
    </div>
  )
}

export default CollapseNav;
