import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"


function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="stylesheet" href="/css/bootstrap.min.css" />
            <link rel="stylesheet" href="/css/et-line-icons.css"/>
            <link rel="stylesheet" href="/css/font-awesome.min.css"/>
            <link rel="stylesheet" href="/css/iconmonstr-iconic-font.min.css"/>
            <link rel="stylesheet" href="/css/lity.min.css"/>
            <link rel="stylesheet" href="/css/animate.css"/>
            <link rel="stylesheet" href="/css/owl.carousel.min.css"/>
            <link rel="stylesheet" href="/css/owl.theme.default.min.css"/>
            <link rel="stylesheet" href="/css/main.css" />
            {/* <link rel="stylesheet" href="/css/responsive.css" /> */}
            <link href="https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700,800%7cPoppins:100,200,300,400,500,600,700,800" rel="stylesheet" />
            {/* <link href="https://fonts.googleapis.com/css?family=Tajawal" rel="stylesheet" /> */}
            <link href="https://fonts.googleapis.com/css?family=Tajawal&display=swap&subset=arabic" rel="stylesheet" />
            {/* <link href="https://fonts.googleapis.com/css?family=Cairo" rel="stylesheet" /> */}


            {/* jis files */}
            {/* <script src="/js/jquery-3.3.1.min.js"></script> */}
            {/* <script src="/js/jquery-migrate-3.0.0.min.js"></script> */}
            <script src="/js/popper.min.js"></script>
            <script src="/js/bootstrap.min.js"></script>
            <script src="/js/jquery.counterup.min.js"></script>
            <script src="/js/jquery.waypoints.min.js"></script>
            <script src="/js/lity.min.js"></script>
            <script src="/js/lightbox.js"></script>
            <script src="/js/scrollIt.min.js"></script>
            <script src="/js/validator.js"></script>
            <script src="/js/owl.carousel.min.js"></script>
            <script src="/js/isotope.pkgd.min.js"></script>
            <script src="/js/main.js"></script>
            <script src="/js/wow.min.js"></script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
