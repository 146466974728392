/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */

import { useState, useEffect } from "react";
import { windowGlobal } from "../utils/helperFunction";

export function useScroll() {
  const [scrollY, setScrollY] = useState(0);

  const listener = (e) => {
    setScrollY(windowGlobal.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return {
    scrollY,
  };
}